import store from "../store/index";
const EVENT_NAME = process.env.REACT_APP_NR_EVENT_NAME || "MPGiftiglobalEventdevelop"
export const trackUserJourney = (step,isError,errMessage,orderId) =>{
    const userID = store?.getState()?.auth?.user?.id
    try {
      if (typeof newrelic == 'object') {
        // eslint-disable-next-line no-undef
        newrelic.addPageAction(EVENT_NAME, {
          userId: userID,
          Step: step,
          isError: isError,
          message: errMessage,
          orderId: orderId,
        });
      }
    } catch (error) {
        console.error(error,EVENT_NAME);
      }
}

export const NrUserEvent = (step,isError,message,orderId) =>{
  trackUserJourney(step, isError, message,orderId)
}