import React from 'react';
import { AppRouter } from './routes';
import i18nConfig from './translations/i18n';

import './App.scss';
import { useSelector } from 'react-redux';
import { getAuthState } from './features/auth/redux/auth.reducer';

i18nConfig();

function App() {

  const { user } = useSelector(getAuthState) || {};
  if (typeof window.newrelic === "object") window.newrelic?.setUserId(user?.id || null);

  return (
    <div className="App-wrapper">
      <AppRouter />
    </div>
  );
}

export default App;
