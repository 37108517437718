import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

import { ConfirmOrder } from '../../orders/components/ConfirmOrder';
import { ROUTES } from '../../../routes';
import { useCart } from '../../cart/hooks/useCart';
import { useOrders } from '../../orders/hooks/useOrders';
import { SuccessPaymentModal } from './SuccessPaymentModal';
import { NrUserEvent } from '../../../utils/NrEvent';

export const SuccessComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { onClearCartState } = useCart();
  const { onProcessOrderAfterRedirectAction, onClearOrdersState, orderStatus } = useOrders();
  const search = useMemo(() => history.location.search.split('?order_id=')[1] || null, [history.location.search]);
  const orderId = useMemo(
    () => search.substring(0, search.indexOf('&') !== -1 ? search.indexOf('&') : search.length),
    [search]
  );
  const [showModal, setShowModal] = useState(false);
  const [loadOrder, setLoadOrder] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const orderInit = async () => {
    if (orderId) {
      if (search.indexOf('cko-session-id') !== -1 || search.indexOf('pi_id') !== -1) {
        NrUserEvent('orderSuccessful', false, 'process_order_after_redirect API Call', orderId);
        await onProcessOrderAfterRedirectAction({ order_id: orderId });
      } else {
        onClearOrdersState();
        onClearCartState();
        setShowModal(true);
        setLoadOrder(true);
      }
    }
  };
  useEffect(() => {
    orderInit();
  }, []);

  useEffect(() => {
    if (orderStatus === 'APPROVED') {
      onClearOrdersState();
      onClearCartState();
      setShowModal(true);
      setLoadOrder(true);
    }
    if (orderStatus === 'FAILED' && orderId) {
      NrUserEvent('orderFailed', true, 'Order Status Failed', orderId);
      window.location.href = `${window.location.origin}/failure?order_id=${orderId}`;
    }
  }, [orderStatus, orderId, onClearOrdersState, onClearCartState]);

  return (
    <Container fluid>
      <Row className="mt-2">
        <Col xs={12} sm={12} md={5}>
          <div className="go-back-to-homepage-box p-5 mt-4">
            <Row>
              <Col xs={12} className="text-center">
                <h2 className="text-center">{t('common.goBackToHomePage')}</h2>
              </Col>
              <Col xs={12} className="text-center mt-2">
                <p>{t('payment.treatYourSelf')}</p>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <Button
                  className="profile-btn mt-2 btn-block"
                  variant="info"
                  type="button"
                  onClick={() => history.push({ pathname: ROUTES.home })}
                  style={{ maxWidth: '100%' }}
                >
                  {t('common.backToHomePage')}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12} sm={12} md={7}>
          {loadOrder ? <ConfirmOrder key={`1`} showOrdersHeading={false} /> : null}
        </Col>
      </Row>
      {showModal && <SuccessPaymentModal onModalClose={handleModalClose} />}
    </Container>
  );
};

export default SuccessComponent;
