import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import {Modals} from '../../../shared';
import {ConfirmOrder} from '../../orders/components/ConfirmOrder';
import {useCart} from '../../cart/hooks/useCart';
import {useOrders} from '../../orders/hooks/useOrders';

import FailedIcon from '../../../assets/media/icons/failed.svg';
import { NrUserEvent } from '../../../utils/NrEvent';

const FailureComponent = () => {
  const { t } = useTranslation();
  const { onClearCartState } = useCart();
  const { onFailedOrderAction,onClearOrdersState, ...orderState } = useOrders();
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    setErrorMessage(orderState.error || orderState.order_checkout_error);
    const search = history.location.search.split('?order_id=')[1] || '';
    const orderId =
      orderState.orderid || search.substring(0, search.indexOf('&') !== -1 ? search.indexOf('&') : search.length);
    if (orderId) {
      NrUserEvent('orderFailed', true, 'failed_order_after_redirect API Call', orderId);
    }
    await onFailedOrderAction({ order_id: orderId });
    onClearCartState();
    onClearOrdersState();
    setShowModal(true);
  }, [onClearCartState, onFailedOrderAction]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const modalBody = () => {
    return (
      <Container fluid className="p-2">
        <Row>
          <Col xs={12} className="text-center">
            <Image src={FailedIcon} rounded className="rounded" style={{ width: '4em' }} />
          </Col>
          <Col xs={12} className="text-center pt-2">
            <h2>{t('payment.paymentFailed')}</h2>
          </Col>
        </Row>
        <Row>
          {errorMessage && !errorMessage?.includes('500') && (
            <Col xs={12} className="pt-3 text-center">
              <p style={{ opacity: '0.9' }}>{errorMessage}</p>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Button
              className="profile-btn mt-2 btn-block text-capitalize"
              variant="info"
              size="lg"
              type="button"
              onClick={handleModalClose}
            >
              {t('common.ok')}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  const getFailure = () => {
    return (
      <>
        {<ConfirmOrder failed={true} />}
        {showModal ? <Modals.GModal show={showModal}>{modalBody()}</Modals.GModal> : null}
      </>
    );
  };

  return getFailure();
};

export default FailureComponent;
